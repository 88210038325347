// - - - - - - - - Buttons

.button {
  &--text {
    line-height: 18px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: $text-dark-color;
    font-weight: $title-weight;
    text-transform: uppercase;
    cursor: pointer;
    margin: 15px 0;
    background-color: transparent;

    &.--arrowright {
      --color-var: #{$text-dark-color};

      &::after {
        content: "";
        display: flex;
        width: 20px;
        height: 10px;
        mask: url("/images/icons/arrow_right.svg") right center no-repeat;
        -webkit-mask: url("/images/icons/arrow_right.svg") right center no-repeat;
        margin-left: 4px;
        background-color: var(--color-var);
        align-self: center;
        margin-bottom: 3px;
      }

      &:hover {
        transform: translateX(2px);
        opacity: 0.7;
      }
    }

    &.--arrowleft {
      --color-var: #{$text-dark-color};

      &::before {
        content: "";
        display: flex;
        width: 20px;
        height: 10px;
        mask: url("/images/icons/arrow_right.svg") right center no-repeat;
        -webkit-mask: url("/images/icons/arrow_right.svg") right center no-repeat;
        margin-right: 4px;
        background-color: var(--color-var);
        transform: rotate(180deg);
        align-self: center;
      }

      &:hover {
        transform: translateX(-2px);
        opacity: 0.7;
      }
    }

    &--accent {
      @extend .button--text;
      color: $accent-color;

      &.--arrowright::after,
      &.--arrowleft::before {
        background-color: $accent-color;
      }
    }

    &:hover {
      color: $accent-color;
    }
  }

  &--fill {
    @extend .button--text;
    color: white;
    background-color: $accent-color;
    padding: 15px 20px;
    border-radius: 20px;
    display: inline-block;
    width: fit-content;
    width: -moz-max-content;

    &:hover {
      color: white;
      background-color: darken($color: $accent-color, $amount: 5);
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &--secondary {
    @extend .button--text;
    color: $accent-color;
    background-color: transparent;
    padding: 15px 20px;
    border: 2px solid $accent-color;
    border-radius: 20px;
    display: inline-block;
    width: fit-content;
    width: -moz-max-content;

    &--white {
      @extend .button--secondary;
      color: white;
      border: 2px solid white;
      background-color: $accent-color;
      align-self: center;

      &:hover {
        color: white;
      }
    }
  }
}

.--center {
  align-self: center;
}