// - - - - - - - - Demo

.demo {
    background-color: $accent-color;
}

.demo__content {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.demo__content__title {
    @extend .title;
    color: $text-light-color;
    text-align: center;
}

.demo__content__text {
    @extend .text;
    color: $text-light-color;
    text-align: center;

    a {
        color: white;

        &:hover {
            color: $text-medium-color;
        }
    }
}