// - - - - - - - - About

.about.--space {
    @include mq (maxtabletp) {
        margin-top: 0;
    }
}

.about__flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq (tabletp) {
        flex-direction: row;
    }
}

.reverse__odd > :nth-child(odd), .reverse__even > :nth-child(even) {
    .about__flex {
        @include mq (tabletp) {
            flex-direction: row-reverse;
        }
    }
}

.about__grid {
    @extend .grid;
    row-gap: 50px;

    &.--four__col {
        @extend .--four__col;
            grid-template-columns: 1fr;

        @include mq(tabletp) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 30px;
        }

        @include mq(tabletl) {
            grid-template-columns: repeat(4, 1fr);
        }

    }
}

.about__image {
    max-width: 100%;
    justify-self: center;
    margin-bottom: 30px;

    @include mq (tabletp) {
        max-width: 45%;
        margin-bottom: 0;
    }
}

.about__content {
    flex-direction: row;
    max-width: 100%;

    @include mq (tabletp) {
        max-width: 45%;
    }
}

.about__title {
    @extend .title;
    margin-top: 0px;
    line-height: 50px;

    &--highlight {
        @extend .about__title;
        text-align: center;
        text-decoration: underline;
        text-decoration-color: $accent-color;
    }
}
 
.about__subtitle {
    @extend .subtitle;
    color: $accent-color;
    font-weight: $text-weight;
    margin-bottom: 18px;
}

.about__text {
    @extend .text;
}

.about__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.about__list__content__icon {
    width: 190px;
    margin-right: auto;
    margin-left: auto;
}

.about__list__content__text {
    text-align: center;
    font-size: 30px;
}