// - - - - - - - - Grant Logos

.grantlogos__content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.grantlogos__content__title {
    text-align: center;
    align-self: center;
}

.grantlogos__content__icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.grantlogos__content__icons__icon {
    margin: 10px 0 0 0;

    @include mq(tabletl) {
        margin: 40px 20px 0 20px;
    }
}

.grantlogos__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    
    @include mq(tabletl) {
        width: 380px;
    }
}

.grantlogos__caption {
    font-size: 12px;
    line-height: 1rem;
    text-align: center;
    width: 300px;
}