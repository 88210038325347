// - - - - - - - - video

.video__content {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
}

.video__content__title {
    @extend .title;
    text-align: center;
    align-self: center;
    line-height: 50px;

}

.video__content__subtitle {
    @extend .subtitle;
    text-align: center;
    align-self: center;
    color: $accent-color;

}

.video__content__text {
    @extend .text;
    text-align: center;
    align-self: center;

    @include mq(tabletl) {
        max-width: 70%;
    }
}

.video__content__video {
    align-self: center;
    margin-top: 60px;

    iframe {
        max-width: 100%;
      }
}