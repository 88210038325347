// - - - - - - - - General Layout (1)

@import "_reset";
@import "_mixins";

// - - - - - - - - Layout

$page-content-margin: 60px 0 110px 0;
$space-margin: 110px 0;

// - - - - - - - - Colors

$background-color: #ffffff;
$background-alt-color: #f9f9f9;
$border-color: #e5636f;

$text-dark-color: #343549;
$text-medium-color: rgba(38, 48, 53, 0.8);
$text-light-color: #ffffff;
$button-text-color: #ffffff;

$accent-color: #960372;
$error-color: #ff3b30;


// - - - - - - - - Font Style

$title-weight: 700;
$text-weight: 400;

$subtitle-small: 22px;
$subtitle-large: 28px;

$title-small: 38px;
$title-large: 56px;

$text-small: 18px;
$text-large: 23px;

// - - - - - - - - Line Height

$subtitle-line-height: 32px;
$subtitle-line-height-small: 28px;
$title-line-height: 72px;
$title-line-height-small: 48px;
$text-line-height: 32px;
$text-line-height-small: 28px;

// - - - - - - - - Logo

$logo-width: 130px;
$footer-logo-width: 150px;

// - - - - - - - - General Layout (2)

@import "_typography";
@import "_buttons";
@import "_layout";
@import "_font";

// - - - - - - - - Components

@import "_includes/_component__footer";
@import "_includes/_component__header";
@import "_includes/_component__newsletter";
@import "_includes/_component__contact";
@import "_includes/_component__demo";
@import "_includes/_component__about";
@import "_includes/_component__faq";
@import "_includes/_component__grantlogos";
@import "_includes/_component__video";
@import "_includes/_component__languageselection";
@import "_includes/_component__multilingual";