// - - - - - - - - Footer

.footer {
	padding: 40px 0;
	@include transition(all 0.4s ease-in-out 0.8s);
	text-align: center;
	background-color: $background-alt-color;

	@include mq(tabletl) {
		padding: 60px 0;
		text-align: left;
	}
}

.footer__subtitle {
	@extend .subtitle;
	font-size: $subtitle-small - 4px;
	font-weight: $title-weight;
	margin: 0 0 10px 0;
}

.footer__text {
	@extend .text;
	font-size: $text-small - 2px;
	margin: 0;
	font-weight: $text-weight;
	display: flex;
	flex-direction: column;

	@include mq(tabletl) {
		font-size: $text-large - 7px;
	}
}

.footer__logo {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;

	img {
		height: $footer-logo-width - 50px;
		width: auto;
	}
}