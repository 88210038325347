// - - - - - - - - Contact

.contact {
    background-color: $background-alt-color;
}

.contact__content {
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
}

.contact__content__title {
    @extend .title;
    text-align: center;
    align-self: center;
}

.contact__content__text {
    @extend .text;
    text-align: center;
    align-self: center;

    @include mq(tabletl) {
        max-width: 70%;
    }
}