// - - - - - - - - Layout

html, body {
    height: 100%;
    background: $background-color;
    overflow: scroll;
    scroll-behavior: smooth;
}

hr {
    border: 0;
    height: 2px;
    background-color: $border-color;
}

.page__content {
    width: 100%;
    margin: $page-content-margin;
    word-break: break-word;

    @include mq (maxtabletp) {
        margin-top: 30px;
    }
}

// - - - - Page Header

.page__header {
    @include mq (tabletp) {
        margin: 60px 0;
    }
}

.page__header__title {
    @extend .title;
    margin: 0 0 24px 0;
}

.page__header__subtitle {
    @extend .subtitle;
    margin: 0 0 16px 0;
}

.page__header__text {
    @extend .text;
    margin: 0;
}

.page__header__image {
    width: 100%;
    max-width: 1440px;
    margin: 40px 0;
}

// - - - - Space

.--space {
    margin: $space-margin;

    &__top {
        margin-top: 150px;

        @include mq(tabletl) {
            margin-top: 260px;
        }
    }

    &__bottom {
        margin-bottom: 110px;
    }
}

// - - - - Wrapper

.wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1200px;
}

// - - - - Grid

.grid {
    display: grid;
    gap: 30px;

    &.--two__col, &.--three__col, &.--four__col {
        grid-template-columns: 1fr;
    }

    @include mq(tabletl) {
  
        &.--two__col {
          grid-template-columns: repeat(2, 1fr);
        }
      
        &.--three__col {
          grid-template-columns: repeat(3, 1fr);
        }
    
        &.--four__col {
          grid-template-columns: repeat(4, 1fr);
        }
    }
}

// - - - - Lock

.lock {
    overflow: hidden;
}

// - - - - target margin

:target::before {
  content: "";
  display: block;
  height: 80px;
  margin: -80px 0 0;
  background: $background-color;
}

// - - - - target margin

.website__hidden {
    display: none;
}

.screenreader__only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}