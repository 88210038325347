// - - - - - - - - Typography

body {
	font-family: Poppins, sans-serif;
	font-style: normal;
	color: $text-dark-color;
	-webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    word-wrap: break-word;
}

// - - - - Fluid Font

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

.subtitle {
    font-weight: $text-weight;
    letter-spacing: 0rem;
    color: $text-dark-color;
    font-size: $subtitle-small;
    margin-bottom: 18px;
            
    $min-vw-calc: strip-unit($subtitle-small * $break-tabletl) / strip-unit($subtitle-large) + 'px';
            
    @media screen and (max-width: $break-tabletl) and (min-width: $min-vw-calc) {
        font-size: calc(#{strip-unit($subtitle-large / $break-tabletl)} * 100vw);
    }
          
    @media screen and (min-width: $break-tabletl) {
        font-size: $subtitle-large;
    }
}

h1, .title {
    font-weight: $title-weight;
    letter-spacing: 0rem;
    color: $text-dark-color;
    font-size: $title-small;
    margin-top: 40px;
    margin-bottom: 18px;
    line-height: $title-line-height-small;
            
    $min-vw-calc: strip-unit($title-small * $break-tabletl) / strip-unit($title-large) + 'px';
            
    @media screen and (max-width: $break-tabletl) and (min-width: $min-vw-calc) {
        font-size: calc(#{strip-unit($title-large / $break-tabletl)} * 100vw);
    }
          
    @media screen and (min-width: $break-tabletl) {
        font-size: $title-large;
        line-height: $title-line-height;
    }
}

h2 {
    font-weight: $title-weight;
    letter-spacing: 0rem;
    color: $text-dark-color;
    font-size: $title-small - 6px;
    margin-top: 40px;
    margin-bottom: 14px;
    line-height: $title-line-height-small;
            
    $min-vw-calc: strip-unit($title-small * $break-tabletl) / strip-unit($title-large) + 'px';
            
    @media screen and (max-width: $break-tabletl) and (min-width: $min-vw-calc) {
        font-size: calc(#{strip-unit(($title-large - 6px) / $break-tabletl)} * 100vw);
    }
          
    @media screen and (min-width: $break-tabletl) {
        font-size: $title-large - 16px;
        line-height: $title-line-height;
    }
}

h3 {
    font-weight: $title-weight;
    letter-spacing: 0rem;
    color: $text-dark-color;
    font-size: $title-small - 12px;
    margin-top: 40px;
    margin-bottom: 12px;
    line-height: $title-line-height-small - 12px;
            
    $min-vw-calc: strip-unit($title-small * $break-tabletl) / strip-unit($title-large) + 'px';
            
    @media screen and (max-width: $break-tabletl) and (min-width: $min-vw-calc) {
        font-size: calc(#{strip-unit(($title-large - 12px) / $break-tabletl)} * 100vw);
    }
          
    @media screen and (min-width: $break-tabletl) {
        font-size: $title-large - 24px;
        line-height: $title-line-height - 24px;
    }
}

h4, h5, h6 {
    font-weight: $title-weight;
    letter-spacing: 0rem;
    color: $text-dark-color;
    font-size: $title-small - 16px;
    margin-top: 40px;
    margin-bottom: 8px;
    line-height: $title-line-height-small - 16px;
            
    $min-vw-calc: strip-unit($title-small * $break-tabletl) / strip-unit($title-large) + 'px';
            
    @media screen and (max-width: $break-tabletl) and (min-width: $min-vw-calc) {
        font-size: calc(#{strip-unit(($title-large - 16px) / $break-tabletl)} * 100vw);
    }
          
    @media screen and (min-width: $break-tabletl) {
        font-size: $title-large - 32px;
        line-height: $title-line-height - 32px;
    }
}

p, blockquote, li, ul, ol, .text {
    font-weight: $text-weight;
    letter-spacing: 0rem;
    color: $text-medium-color;
    font-size: $text-small;
    margin-bottom: 24px;
    line-height: $text-line-height-small;
            
    $min-vw-calc: strip-unit($text-small * $break-tabletl) / strip-unit($text-large) + 'px';
            
    @media screen and (max-width: $break-tabletl) and (min-width: $min-vw-calc) {
        font-size: calc(#{strip-unit($text-large / $break-tabletl)} * 100vw);
    }
          
    @media screen and (min-width: $break-tabletl) {
        font-size: $text-large;
        line-height: $text-line-height;
    }
}

.text__bold {
    font-weight: $title-weight;
}

a {
    font-weight: $title-weight;
    letter-spacing: 0rem;
    color: $text-medium-color;
    font-size: $text-small;
    line-height: $text-line-height-small;
            
    $min-vw-calc: strip-unit($text-small * $break-tabletl) / strip-unit($text-large) + 'px';
            
    @media screen and (max-width: $break-tabletl) and (min-width: $min-vw-calc) {
        font-size: calc(#{strip-unit($text-large / $break-tabletl)} * 100vw);
    }
          
    @media screen and (min-width: $break-tabletl) {
        font-size: $text-large;
        line-height: $text-line-height;
    }

    &:hover {
        color: $accent-color;
        transition: 0.3s;
    }
}

ol, ul {
    margin: 40px 0;
}

li {
    margin-left: 2em;
}

li.checkmark {
    list-style: none;
    line-height: $text-line-height-small;
    display: flex;
    flex-direction: row;
    margin: 12px 0;
    text-align: left;

    img {
        width: 20px;
        margin-right: 10px;
    }
}

blockquote {
    margin-top: 16px;
    border-left: 2px solid $accent-color;
    padding: 10px 0 10px 32px;
}

strong {
    color: $text-dark-color;
}

.--light {
    color: $text-medium-color;
}

::selection {
    background: $accent-color;
    color: $background-color;
}
::-moz-selection {
    background: $accent-color;
    color: $background-color;
}