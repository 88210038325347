// - - - - - - - - Newsletter

.newsletter {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $background-alt-color;

    @include mq(tabletp) {
        background-size: 60%;
    }

    @include mq(tabletl) {
        background-size: 40%;
    }
}

.newsletter__content {
    padding-top: 15vh;
    padding-bottom: 15vh;
    display: flex;
    flex-direction: column;
}

.newsletter__content__title {
    @extend .title;
    align-self: center;
    text-align: center;
}

.newsletter__content__text {
    @extend .text;
    color: $text-dark-color;
    align-self: center;
    text-align: center;
    
    @include mq(tabletl) {
        max-width: 70%;
    }
}

.newsletter__content__success {
    @extend .newsletter__content__text;
    color: $accent-color;

    a {
        color: $accent-color;
    }
}

.newsletter__content__error {
    @extend .newsletter__content__text;
    color: $error-color;

    a {
        color: $error-color;
    }
}

.newsletter__content__form {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
}

.newsletter__content__form__input {
    border-bottom: 2px solid;
    border-color: $accent-color;
    padding: 10px 10px 10px 0;
    margin-bottom: 10px;
    margin-right: 0;
    font-size: 18px;
    width: 300px;
    background-color: transparent;
    -webkit-appearance: none;
    border-radius: 0;
    transition: color border-color .2s;

    @include mq(tabletp) {
        width: 400px;
        margin-right: 15px;
    }

    &::placeholder {
        color: $accent-color;
        transition: color .2s;
    }

    &.error {
        border-color: $error-color;
        color: $error-color;

        &::placeholder {
            color: $error-color;
        }
    }
}

.newsletter__content__form__note {
    font-size: 14px;
    color: $text-medium-color;
    text-align: center;
    line-height: 1rem;
    width: 70%;
    align-self: center;

    @include mq(tabletp) {
        width: 50%;
    }
}

.newsletter__content__result {
    &> div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: opacity .2s linear;
        opacity: 0;
        height: 0;
        visibility: hidden;

        div {
            margin-bottom: 0;
        }
    }
    
    &:not(.confirm__email):not(.subscription_success):not(.validation__error):not(.generic__error) > div:nth-child(1),
    &.confirm__email > div:nth-child(2),
    &.subscription_success > div:nth-child(3),
    &.validation__error > div:nth-child(4),
    &.generic__error > div:nth-child(5) {
        opacity: 1;
        height: 100%;
        visibility: visible;
    }
}
