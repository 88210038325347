  .language-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
  }

  .language-list {
    list-style: none;
    margin: 0;
    text-align: center;
  }

  .language-list li {
    margin: 0;
    padding: .5em;
  }