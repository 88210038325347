// - - - - - - - - Header

.header {
  padding: 24px 0;
  background-color: $background-color;
  position: sticky;
  top: 0;
  z-index: 20;
  
  &--banner {
    @extend .header;
    padding: 0 0 40px 0;
  }

  @include mq(tabletl) {

    &--banner {
      @extend .header;
      padding: 0 0 40px 0;
    }
  }
}

.header__title {
  text-align: right;
  color: $text-dark-color;
  font-size: 30px;
  font-weight: $title-weight;

  &:hover {
    color: $accent-color;
    transition: 0.3s;
  }

  @include mq(tabletl) {
    text-align: left;
  }
}

.header__grid {
  @extend .grid;

  &.--two__col {
    @extend .--two__col;
    grid-template-columns: 1fr 2fr;
  }
  &.--three__col {
    @extend .--three__col;
    grid-template-columns: auto 2fr auto;

    @include mq(tabletl) {
      grid-template-columns: 1fr 2fr auto;
    }
  }
}
  
.header__logo {
  display: block;
  align-self: center;
  width: $logo-width;
  position: relative;
  justify-self: center;

  @include mq(tabletl) {
    justify-self: left;
  }
}

// - - - - Header Menu

.header__menu, .header__language__menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $background-color;
  position: fixed;
  top: 10px;
  right: 0;
  z-index: 1;
  padding-bottom: 10vh;

  opacity: 0;
  visibility: hidden;
  @include transition(all 0.6s cubic-bezier(0.2, 0.3, 0, 1));

  @include mq(tabletl) {
    flex-direction: row;
    width: initial;
    height: initial;
    position: relative;
    background-color: $background-color;
    justify-self: center;
    opacity: 1;
    visibility: visible;
    top: 0;
    align-self: center;
    display: flex;
    padding-bottom: 0;
  }
}

.header__menu__button {
  top: -2px;
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  border-radius: 8px;

  @include mq(tabletl) {
    display: none;
  }
}

.header__menu__button__closed,
.language__menu__button__closed {
  position: relative;

  span,
  span:before,
  span:after {
    content: "";
    display: block;
    width: 14px;
    height: 3px;
    @include transition(all 0.12s);
    background: $accent-color;
    border-radius: 4px;
  }

  span:before {
    position: absolute;
    width: 18px;
    top: -6px;
  }

  span:after {
    position: absolute;
    width: 18px;
    bottom: -6px;
  }
}

.header__menu__button__open .header__menu__button,
.language__menu__button__open .language__menu__button__closed {
  span {
    background-color: transparent !important;
  }

  span:before,
  span:after {
    top: 0;
  }

  span:before {
    @include rotate(45);
  }

  span:after {
    @include rotate(-45);
  }
}

.header__language__menu__button {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  div:first-of-type {
    font-size: $text-small;
    opacity: 1;
    @include transition(all .12s);
  }
}

.language__menu__button__closed {
  top: -0.9em;
  margin-left: 1px;

  span,
  span:before,
  span:after {
    background: transparent;
  }
}

.language__menu__button__open {
  .header__language__menu__button {
    div:first-of-type {
      opacity: 0;
    }

    .language__menu__button__closed {
      span:before,
      span:after {
        background: $accent-color;
      }
    }
  }

  .header__menu__button {
    z-index: 1;
  }
}

.header__menu__button__open {
  .header__language__menu__button {
    z-index: 0;
  }
}

.header__menu__button__open .header__menu,
.language__menu__button__open .header__language__menu {
  opacity: 1;
  visibility: visible;
  top: 0px;
  overflow: hidden;
}

.header__menu__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;

  li {
    list-style: none;
    line-height: $text-line-height-small;
    margin-left: 0;

    &:before {
      content: none;
    }
  }

  @include mq(tabletl) {
    margin-top: 0;
    margin-bottom: 0px;
    flex-direction: row;
  }
}

.header__menu__list__title {
  margin-bottom: 24px;

  @include mq(tabletl) {
    margin-bottom: 0px;
    margin-top: 0px;

    &:not(:first-child) {
      margin-left: 32px;
    }
  }
}

.header__menu__list__link {
  font-size: $subtitle-large + 2;
  font-weight: $title-weight;
  text-transform: none;
  color: $text-dark-color;

  &.--active-link {
    color: $accent-color;
  }

  &:hover {
    color: $accent-color;
  }

  @include mq(tabletl) {
    font-size: $text-small;
    display: inline-block;
  }
}

.header__language__menu {
  @include mq(tabletl) {
    .header__menu__list__title:not(:first-child) {
      margin-left: .5em;
    }
  }

  .header__menu__list__link {
    color: $text_medium_color;

    &.--active-link {
      color: $accent-color;
    }

    &:hover {
      color: $accent-color;
    }
  }

  .short {
    display: none;
  }
  .long {
    display: inline;
  }

  @include mq(tabletl) {
    display: block;

    .short {
      display: inline;
    }
    .long {
      display: none;
    }
  }
}

.header__language__menu__button {
  position: relative;
  top: -2px;
  cursor: pointer;
  font-weight: bold;
  color: $accent-color;
  z-index: 5;

  @include mq(tabletl) {
    display: none;
  }
}