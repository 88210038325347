// - - - - - - - - FAQ

.faq__title {
    @extend .title;
    text-align: center;
}

.faq__accordion {
    background-color: $background-alt-color;
    color: $text-dark-color;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    transition: 0.4s;
    @extend .subtitle;
  }
  
.faq__accordion:hover {
    background-color: $accent-color;
    color: $text-light-color;
}

.faq__accordion__content {
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.faq__accordion__content__text {
    @extend .text;
    color: $text-dark-color;
    line-height: 1.8rem;
}

.faq ul, .faq ol {
    margin-top: 0;

    li {
        margin-bottom: .3rem;
    }
}
